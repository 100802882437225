import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CONTENT} from '../../../content-management/content';
import {IMAGECONSTANTS} from '../../../shared/constants/image-constants';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {CommonService} from '../../../shared/services/common.service';
import {DataService} from '../../../core/services/data.service';
import {CreatePortalAccountService} from '../create-portal-account.service';
import {ValidatePolicyRequest} from '../../../models/request/validate-policy-request';
import {ValidatePolicyResponse} from '../../../models/response/validate-policy-response';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import {EncrDecrService} from '../../../core/services/encr-decr.service';


@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss']
})
export class RegisterAccountComponent implements OnInit {

  public registerForm: UntypedFormGroup;
  public policyInfo: ValidatePolicyRequest = {};
  public isSubmitted = false;
  public content = CONTENT;
  public imageConstants = IMAGECONSTANTS;
  public formValidation = FormValidationConstants;
  public routingConstants = ROUTINGCONSTANTS;

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private commonService: CommonService,
              private dataService: DataService,
              private gtmService: GtmEventsService,
              private encDcrService: EncrDecrService,
              private createPortalService: CreatePortalAccountService) {
  }

  ngOnInit() {
    this.createForm();
    this.populateForm();
  }

  createForm() {
    const lengthValidation = this.formValidation.lengthValidation;
    const patternValidation = this.formValidation.patternValidation;
    this.registerForm = this.formBuilder.group({
      policyNumber: ['', [Validators.required, Validators.maxLength(lengthValidation.policyNumLength)]],
      firstName: ['', [Validators.required, Validators.maxLength(lengthValidation.nameLength)]],
      lastName: ['', [Validators.required, Validators.maxLength(lengthValidation.nameLength)]],
      zipCode: ['', [Validators.required, Validators.pattern(patternValidation.zipCode),
        Validators.maxLength(lengthValidation.zipCodeMaxLength), Validators.minLength(lengthValidation.zipCodeMinLength)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(lengthValidation.phoneMinLength),
        Validators.maxLength(lengthValidation.phoneMaxLength)]]
    });
  }

  get formValidator() {
    return this.registerForm.controls;
  }

  numbersOnly(event: KeyboardEvent): boolean {
    return (event.key >= '0' && event.key <= '9') || event.key === ' ' || event.key === 'backspace';
  }

  navigateToLoginPage() {
    this.router.navigate([this.routingConstants.login]);
  }

  public setPhoneNumberError(formControlName) {
    const error = this.formValidator[formControlName].errors;
    const content = this.content.validations.registerAccount;
    if (this.formValidator[formControlName].touched && error) {
      if (error.required) {
        return content.phoneNumReq;
      } else if (error.minlength) {
        return content.phoneNumLengthErr;
      } else if (error.maxlength) {
        return content.phoneNumLengthErr;
      }
    }
  }

  public setZipCodeError(formControlName) {
    const error = this.formValidator[formControlName].errors;
    const content = this.content.validations.registerAccount;
    if (this.formValidator[formControlName].touched && error) {
      if (error.required) {
        return content.zipCodeReq;
      } else if (error.minlength) {
        return content.zipCodeLengthError;
      } else if (error.maxlength) {
        return content.zipCodeLengthError;
      }
    }
  }

  setDocumentValues(request, formControlName) {
    return this.policyInfo[request] = this.registerForm.value[formControlName];
  }

  populateDocument() {
    this.setDocumentValues('policyNumber', 'policyNumber');
    this.setDocumentValues('firstName', 'firstName');
    this.setDocumentValues('lastName', 'lastName');
    this.setDocumentValues('zipCode', 'zipCode');
    this.setDocumentValues('homeTel', 'phoneNumber');
    this.dataService.createUser.policyInfo = this.policyInfo;
    this.dataService.policyInfo = this.policyInfo;
    return this.policyInfo;
  }

  populateForm() {
    const policyInfo = this.dataService.policyInfo;
    this.setFormValues('policyNumber', policyInfo.policyNumber);
    this.setFormValues('firstName', policyInfo.firstName);
    this.setFormValues('lastName', policyInfo.lastName);
    this.setFormValues('zipCode', policyInfo.zipCode);
    this.setFormValues('phoneNumber', policyInfo.homeTel);
  }

  setFormValues(formControlName, value) {
    this.registerForm.controls[formControlName].setValue(value);
  }

  onSubmit() {
    this.populateDocument();
    this.isSubmitted = true;
    this.createPortalService.validatePolicy(this.populateDocument()).subscribe((policy: ValidatePolicyResponse) => {
      if (policy.insuredCode) {
        this.router.navigate([this.routingConstants.createAccount, this.encDcrService.set(policy.insuredCode)]);
      } else {
        this.setServiceError(policy.standardMessage.code);
      }
      this.setGTMForSubmit(policy.standardMessage);
    });
  }

  setGTMForSubmit(policy) {
    const gtmEvent = {
      event: 'Portal Data Layer',
      eventCategory: 'button click',
      eventAction: 'Register Account Page 1',
      eventLabel: 'Register Account Next Button',
      eventValue: policy.developerMessage,
      userId: sessionStorage.getItem('userId'),
      error: {
        errorCode: policy ? policy.code : '',
        errorMessage: policy ? policy.developerMessage : ''
      }
    };
    this.gtmService.pushGTMTags(gtmEvent);
  }

  public setServiceError(code) {
    if (code === '94') {
      this.commonService.setValidationErrorModal(this.content.validations.registerAccount.policyExists);
    } else {
      this.commonService.setValidationErrorModal(this.content.validations.registerAccount.errorTitle);
    }
  }

}


