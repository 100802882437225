import {Injectable} from '@angular/core';
import {LoginRequest} from '../../models/request/login-request';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../shared/services/common.service';
import {CreateUserRequest} from '../../models/request/create-user-request';
import {LoginResponse} from '../../models/response/login-response';
import {DataService} from './data.service';
import {CreateUserResponse} from '../../models/response/create-user-response';
import {PasswordResetRequest} from '../../models/request/password-reset-request';
import {PasswordResetResponse} from '../../models/response/password-reset-response';
import {ValidateJwtRequest} from '../../models/request/validate-jwt-request';
import {JwtService} from './jwt.service';
import {EncrDecrService} from './encr-decr.service';
import * as CryptoJS from 'crypto-js';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { getCookieValue, setCookieValue, UNUM_COOKIE_INFO, UNUM_COOKIE_KEY } from '../../shared/helpers/helper-functions';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<LoginRequest>;
  public currentUser: Observable<LoginRequest>;
  public environment = environment;
  public setNewPasswordUrl = environment.baseUrl + 'members/v1/forgot-password';
  public logOutUrl = environment.nationwideLogOut;
  public reCaptchaUrl = environment.verifyReCaptchaUrl;
  public NWDeveloperClientId = environment.NWDeveloperClientId;

  constructor(private http: HttpClient, private commonService: CommonService, private dataService: DataService, private jwtService: JwtService, private encrDecrService: EncrDecrService, public oidcSecurityService: OidcSecurityService) {
    this.currentUserSubject = new BehaviorSubject<LoginRequest>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginRequest {
    return this.currentUserSubject.value;
  }

  public authenticate(loginRequest: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginRequest>(this.environment.baseUrl + 'members/v1/authenticate', loginRequest).pipe(
      map(user => {
        if (user && user['jwt']) {
          this.setJWTToken(user, loginRequest.loginId);
        }
        return user as LoginResponse;
      })
    );
  }

  public validateRenEnterpriseJWTToken(request: ValidateJwtRequest): Observable<LoginResponse> {
    //const accessToken = sessionStorage.getItem('access_token');
    const contentTypeHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      //'AccessToken': `${accessToken}`
    });
    return this.http.post(this.environment.renbaseUrl + 'members/v1/validateEnterpriseJWT', request, {headers: contentTypeHeader}).pipe(
      map(token => {
        if (token && token['jwt']) {
          //const decodedToken = this.jwtService.DecodeToken(token['jwt']);
          //const loginId = decodedToken['loginId'];
          //this.setJWTToken(token, loginId);
          sessionStorage.setItem('rencurrentUser', token['jwt']);

        }
        return token as LoginResponse;
      }));
  }

  public validateEnterpriseJWTToken(request: ValidateJwtRequest): Observable<LoginResponse> {
    const accessToken = sessionStorage.getItem('access_token');
    const XNWMessageId = sessionStorage.getItem('XNWMessageId');
    const contentTypeHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'X-NW-Message-Id': XNWMessageId,
      client_id:  environment.client_id
    });
    return this.http.post(this.environment.baseUrl + 'member-management/pet-php-members/v1/validateenterprisejwt', request, {headers: contentTypeHeader}).pipe(
        map(token => {
        if (token && token['jwt']) {         
          const decodedToken = this.jwtService.DecodeToken(token['jwt']);
          const loginId = decodedToken['userId'];
          this.setJWTToken(token, loginId);
        }
        return token as LoginResponse;
      }));
  }

  public updatePassword(request: PasswordResetRequest): Observable<PasswordResetResponse> {
    return this.http.post<PasswordResetRequest>(this.setNewPasswordUrl, request).pipe(
      map(result => {
        if (result && result['jwt']) {
          this.setJWTToken(result, request.loginId);
        }
        return result as PasswordResetResponse;
      })
    );
  }

  /*public validateReCaptcha(request: VerifyReCaptchaRequest): Observable<VerifyReCaptchaResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      client_id: this.NWDeveloperClientId
    });
    return this.http
      .post<VerifyReCaptchaResponse>(this.reCaptchaUrl, request, {headers})
      .pipe(map(result => result as VerifyReCaptchaResponse));
  }*/

  public setJWTToken(user, loginId) {
    sessionStorage.setItem('userId', loginId);
    sessionStorage.setItem('auth service current user', JSON.stringify(user['jwt']));
    sessionStorage.setItem('currentUser', JSON.stringify(user['jwt']));
    this.commonService.setSideNav(true);
    this.currentUserSubject.next(user['jwt']);
  }

  public createAccount(createUser: CreateUserRequest): Observable<CreateUserResponse> {
    return this.http.post<CreateUserRequest>(this.environment.baseUrl + 'members/v1/create', createUser).pipe(
      map(user => {
        if (user && user['jwt']) {
          this.setJWTToken(user, createUser.loginId);
        }
        return user as CreateUserResponse;
      })
    );
  }

  public isLoggedIn() {
    return sessionStorage.getItem('currentUser') !== null;
  }

  public logout() {
    const isUnumUser = getCookieValue(UNUM_COOKIE_KEY) ?  JSON.parse(getCookieValue(UNUM_COOKIE_KEY)) : false;
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('dataService');
    sessionStorage.removeItem('pastDueList');
    sessionStorage.removeItem('renewalStatusList');
    sessionStorage.removeItem('newList');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('isPortalUser');
    sessionStorage.removeItem('policyList');
    sessionStorage.removeItem('rencurrentUser');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('XNWMessageId');
    sessionStorage.removeItem('refreshurl');
    sessionStorage.removeItem('auth service current user');
    sessionStorage.removeItem('memberName');
    sessionStorage.removeItem('activePolicy');
    sessionStorage.removeItem('activeMemberId');
    sessionStorage.removeItem('activeAccountId');
    sessionStorage.removeItem('expiredList');
    sessionStorage.removeItem('portalContenful');
    sessionStorage.removeItem('portalImageContenful');
    this.commonService.setSideNav(false);
    this.currentUserSubject.next(null);
    if (isUnumUser) {
      this.oidcSecurityService.logoff();
    } else {
      window.open(this.logOutUrl, '_parent');
    }
  }
}
