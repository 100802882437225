import { BillingPaymentService } from '../../../shared/services/billing-payment.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CONTENT } from '../../../content-management/content';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { PREFERENCES } from '../../../shared/constants/preferences';
import { PaymentFormData } from '../../../models/payment-form-data';
import { PaymentInfo } from '../../../models/payment-info';
import { PolicyDetails } from '../../../models/policy-details';
import { Router } from '@angular/router';
import { CommonService } from '../../../shared/services/common.service';
import { ROUTINGCONSTANTS } from '../../../shared/constants/routing-constants';
import { GtmEventsService } from '../../../shared/services/gtm-events.service';
import { EncrDecrService } from '../../../core/services/encr-decr.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public content = CONTENT;
  public expirationYears: number[];
  public preferences = PREFERENCES;
  public routes = ROUTINGCONSTANTS;
  public submitted = false;
  public paymentData: PaymentFormData;
  public paymentList: PaymentInfo[] = [];
  public policyNumber: string;
  public selectPetForm: UntypedFormGroup;
  public policy: PolicyDetails;
  public displayProgressSpinner = 0;
  public isBillingInfoRetreived = false;

  @Input() policyDetailsList: PolicyDetails[];
  @Output() public validPayment = new EventEmitter<any>();

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private gtmService: GtmEventsService,
              private commonService: CommonService,
              private encDcrService: EncrDecrService,
              private billingPaymentService: BillingPaymentService) {
  }

  ngOnInit() {
    this.getInitialLoad();
  }

getInitialLoad(){
  if(sessionStorage.getItem('policyList')){
    if(this.displayProgressSpinner>0){
      this.displayProgressSpinner--;
    }
    this.getPaymentsList(null);
  }else{
    if(this.displayProgressSpinner==0){
      this.displayProgressSpinner++;
    }
    setTimeout(() => { 
    this.getInitialLoad();
    },250);
  }
}

  applyPaymentInfo(paymentInfo: PaymentInfo) {
    this.policyNumber = paymentInfo.policyNumber;
    const isCredit = !paymentInfo?.abaNo;    
    return {
      accountNumber: (!isCredit && paymentInfo.maskedAcctNo) || '',
      accountType: paymentInfo.acctType,
      cardNumber: (isCredit && paymentInfo.maskedAcctNo && paymentInfo.maskedAcctNo.toUpperCase()) || '',
      expDate: (isCredit && paymentInfo.cardExpDate.substring(4, paymentInfo.cardExpDate.length) + '/' + paymentInfo.cardExpDate.substring(0, 4)) || '',
      paymentMethod: paymentInfo.payMethod,
      paymentType: isCredit ? (!paymentInfo.maskedAcctNo ? 'Check' : 'Credit card') : 'Debit/Checking account',
      routingNumber: paymentInfo?.abaNo,
      preferences: this.preferences
    };
  }

  changePayment(e: MatSelectChange) {
    if (e.value) {
      this.policyNumber = e.value;
      this.getPaymentsList(this.policyNumber);
      // setTimeout(() => {
      //   this.policy = this.getPolicy(e.value);
      //   console.log(this.paymentList)
      //   const payment = this.paymentList.reduce((acc, pL) => {
      //     if (pL.policyNumber === this.policyNumber) {
      //       acc = pL;
      //     }
      //     return acc;
      //   }, null);
      //   console.log(this.paymentList)
      //   console.log(payment)
      //   if (payment) {
      //     this.paymentData = this.applyPaymentInfo(payment);
      //     this.createForm();
      //   } else {
      //     this.paymentData = null;
      //   }
      // }, 2000);
    }
  }

  getPaymentFormUpdate(policyNumebr){
        this.policy = this.getPolicy(policyNumebr);
        const payment = this.paymentList.reduce((acc, pL) => {
          if (pL.policyNumber === this.policyNumber) {
            acc = pL;
          }
          return acc;
        }, null);
        if (payment) {
          this.paymentData = this.applyPaymentInfo(payment);
          this.createForm();
        } else {
          this.paymentData = null;
        }
  }

  getPolicy(policyNumber: string): PolicyDetails {
    return this.policyDetailsList.reduce((acc, curr) => (curr.policyNumber === policyNumber ? curr : acc), null);
  }

  normalizeAccountType(accountType: string) {
    let res = accountType;
    if (accountType) {
      const lCAccountType = accountType.toLowerCase();
      if (lCAccountType === 'saving') {
        res += 's';
      }
    }
    return res;
  }

  createForm(): void {
    this.selectPetForm = this.fb.group({
      policyNumber: [this.policyNumber]
    });
    this.preferences = this.preferences.map(p => {
      p['hidden'] = true;
      return p;
    });
    this.expirationYears = this.getNext10Years();
  }

  defaultPetPayment() {
    if (this.paymentList.length) {
      this.paymentData = this.applyPaymentInfo(this.paymentList[0]);
      this.policy = this.getPolicy(this.paymentList[0].policyNumber);
    } else {
      this.paymentData = null;
      this.policy = this.policyDetailsList[0];
      this.policyNumber = this.policy.policyNumber;
    }
    this.createForm();
  }

  editDetails(): void {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Edit Payment Information',
      eventLabel: 'Edit Payment Information Button',
      eventValue: 'Accounts: Edit Payment Information',
      userId: sessionStorage.getItem('userId'),
    };
    this.gtmService.pushGTMTags(event);
    this.commonService.setPreviourUrl=this.router.url;
    this.router.navigate([this.routes.editPayment, this.encDcrService.set(this.policyNumber)]);
  }

  getNext10Years(): number[] {
    const today = new Date();
    const currYr = today.getFullYear();
    const next10Years = [];
    for (let i = 0; i <= 10; i++) {
      next10Years.push(currYr + i);
    }
    return next10Years;
  }

  isPaymentInfo() {
    return this.paymentList.length && this.policyDetailsList && this.policyDetailsList.length;
  }

  isGroupPayroll() {
    return this.policy && this.policy.policyType === 'Group Payroll';
  }

  waitForPolicyDetailList() {
    const isPaymentInfo = this.isPaymentInfo();
    this.validPayment.emit(isPaymentInfo);
    if (this.policyDetailsList && this.policyDetailsList.length) {
      if (this.paymentList.length) {
        this.paymentList = this.paymentList.filter(payment => this.policyDetailsList.reduce((acc, curr) => curr.policyNumber === payment.policyNumber || acc, false));
      }
      this.defaultPetPayment();
    } else {
      return setTimeout(() => {
        this.waitForPolicyDetailList();
      }, 250);
    }
  }

  getPaymentsList(policyNumber) {
    this.displayProgressSpinner++;
    const tokenPolicyList = JSON.parse(sessionStorage.getItem('policyList'));
    let policyArr: any = [];
    if (policyNumber != null && tokenPolicyList != null) {
      policyArr = tokenPolicyList.filter(policyObj => policyObj.policyNumber === policyNumber);
      this.paymentList = [];
    } else if (tokenPolicyList && tokenPolicyList[0]) {
      policyArr.push(tokenPolicyList[0]);
    }
    const activeAccountId = policyArr !== null ? policyArr[0].accountId : '';
    this.billingPaymentService.getAllPaymentInfo(activeAccountId).subscribe({
      next: data => {
        if (data) {
          this.paymentList = data['payments'] || [];
          if (policyNumber == null) {
            this.waitForPolicyDetailList();
          } else {
            this.getPaymentFormUpdate(policyNumber);
          }
        }
      },
      error: () => this.displayProgressSpinner--,
      complete: () => {
        this.isBillingInfoRetreived = true;
        this.displayProgressSpinner--;
      }
    });
  }

  handleMask(s: string): string {
    if (s) {
      s = s.replace(/\*/g, 'X');
      s = s.toUpperCase();
    } else {
      s = '';
    }
    return s;
  }

  isCredit(): boolean {
    return this.paymentData && this.paymentData.paymentType && (this.paymentData.paymentType.toUpperCase() === 'CREDIT CARD'  ||  this.paymentData.paymentType.toUpperCase() === 'CHECK');
  }

  isCheck(): boolean{
    return this.paymentData && this.paymentData.paymentType &&  this.paymentData.paymentType.toUpperCase() === 'CHECK';
  }
  goToPaymentHistory() {
      this.router.navigate([this.routes.paymentHistory]);
    }
    
  goToMakeAPayment(policyNumber) {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);
    this.router.navigate([this.routes.makePayment, this.encDcrService.set(policyNumber), selectedPolicy.renewalNumber]);
  }
}
