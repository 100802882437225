import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CONTENT} from '../../../content-management/content';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {DataService} from '../../../core/services/data.service';
import * as _ from 'lodash';
import {PasswordValidator} from '../../../shared/custom-form-validators/confirm-password-validator';
import {EmailValidator} from '../../../shared/custom-form-validators/confirm-email-validator';
import {FormValidationConstants} from '../../../shared/constants/form-validation-constants';
import {AuthService} from '../../../core/services/auth.service';
import {CommonService} from '../../../shared/services/common.service';
import {CreateUserRequest} from '../../../models/request/create-user-request';
import {LoginResponse} from '../../../models/response/login-response';
import {SettingsService} from '../../../shared/services/settings.service';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import {EncrDecrService} from '../../../core/services/encr-decr.service';


@Component({
  selector: 'app-create-login',
  templateUrl: './create-login.component.html',
  styleUrls: ['./create-login.component.scss']
})
export class CreateLoginComponent implements OnInit {
  public createLoginForm: UntypedFormGroup;
  public isSubmitted = false;
  public content = CONTENT;
  public createUser: CreateUserRequest = {};
  public routingConstants = ROUTINGCONSTANTS;
  public formValidation = FormValidationConstants;
  public hide = true;
  public insuredCode: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private dataService: DataService,
              private authService: AuthService,
              private gtmService: GtmEventsService,
              private encDcrService: EncrDecrService,
              private settingService: SettingsService,
              private commonService: CommonService) {
  }

  ngOnInit() {
    this.createUser.policyInfo = this.dataService.policyInfo;
    this.createForm();
    this.navigateToRegistrationPage();
    this.populateForm();
  }

  private getInsuredCodeFromRoute() {
    this.route.params.subscribe((params) => {
      this.insuredCode = this.encDcrService.get(params['insuredCode']);
    });
    return this.insuredCode;
  }

  public navigateToRegistrationPage() {
    if (_.isEmpty(this.dataService.policyInfo)) {
      this.router.navigate([this.routingConstants.registration]);
    }
  }

  public createForm() {
    const validLength = this.formValidation.lengthValidation;
    const validPattern = this.formValidation.patternValidation;
    this.createLoginForm = this.formBuilder.group({
      loginId: ['', [Validators.required, Validators.minLength(validLength.loginIDMinLength),
        Validators.maxLength(validLength.loginIDMaxLength), Validators.pattern(validPattern.loginIdRegex)]],
      password: ['', [Validators.required, Validators.minLength(validLength.passwordMinLength)]],
      confirmPassword: ['', [Validators.required, PasswordValidator('password')]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(validPattern.emailPattern)]],
      confirmEmail: ['', [Validators.required, Validators.email, EmailValidator('email')]],
      securityQuestion: ['', [Validators.required, Validators.minLength(validLength.secQueMinLength), Validators.pattern(validPattern.specialCharacters)]],
      answer: ['', Validators.required]
    });
  }

  public setLoginIdError(formControlName) {
    const error = this.formControl[formControlName].errors;
    const content = this.content.validations.loginPage;
    if (this.formControl[formControlName].touched && error) {
      if (error.required) {
        return content.loginError;
      } else if (error.minlength) {
        return content.loginMinError;
      } else if (error.maxlength) {
        return content.loginMaxError;
      } else if (error.pattern) {
        return content.loginIdPatternError;
      }
    }
  }

  public setPasswordError(formControlName) {
    const error = this.formControl[formControlName].errors;
    const content = this.content.validations.loginPage;
    if (this.formControl[formControlName].touched && error) {
      if (error.required) {
        return content.password;
      } else if (error.minlength) {
        return content.passwordMinLength;
      }
    }
  }

  public setConfirmPasswordError(formControlName) {
    const error = this.formControl[formControlName].errors;
    const content = this.content.validations.loginPage;
    if (this.formControl[formControlName].touched && error) {
      if (error.required) {
        return content.password;
      } else if (error.minlength) {
        return content.passwordMinLength;
      } else if (error.notMatch) {
        return content.loginIdPatternError;
      }
    }
  }

  public navigateToRegistration() {
    this.populateDocument();
    this.router.navigate([this.routingConstants.registration]);
  }

  get formControl() {
    return this.createLoginForm.controls;
  }

  private populateForm() {
    const dataService = this.dataService.createUser;
    this.setFormValues('loginId', dataService.loginId);
    this.setFormValues('securityQuestion', dataService.question);
    this.setFormValues('answer', dataService.answer);
  }

  setFormValues(formControlName, value) {
    return this.createLoginForm.controls[formControlName].setValue(value);
  }

  setDocumentValues(formControlName) {
    return this.createLoginForm.value[formControlName];
  }

  public populateDocument() {
    this.createUser.insuredCode = this.getInsuredCodeFromRoute();
    this.createUser.loginId = this.setDocumentValues('loginId');
    this.createUser.password = this.setDocumentValues('password');
    this.createUser.email = this.setDocumentValues('email');
    this.createUser.question = this.setDocumentValues('securityQuestion');
    this.createUser.answer = this.setDocumentValues('answer');

    this.dataService.createUser = this.createUser;

    return this.createUser;
  }

  public onSubmit() {
    this.populateDocument();
    this.isSubmitted = true;
    this.authService.createAccount(this.createUser)
      .subscribe((data: LoginResponse) => {
        if (data.jwt) {
          this.router.navigate([this.routingConstants.home]);
        } else {
          this.setFormValidationErrors(data.standardMessage.code);
        }
        this.setGTMOnSubmit(data.standardMessage);
      });
  }

  setGTMOnSubmit(data) {
    this.gtmService.pushGTMTags({
      event: 'Portal Data Layer',
      eventCategory: 'button click',
      eventAction: 'Register Account Final Page',
      eventLabel: 'Create Account Form Submit',
      eventValue: data.developerMessage,
      userId: sessionStorage.getItem('userId'),
      error: {
        errorCode: data ? data.code : '',
        errorMessage: data ? data.developerMessage : ''
      }
    });
  }

  setFormValidationErrors(code) {
    switch (code) {
      case '89':
        this.commonService.setValidationErrorModal(this.content.validations.registerAccount.duplicateEmailId);
        break;
      case '90':
        this.commonService.setValidationErrorModal(this.content.validations.registerAccount.duplicateUserId);
        break;
      default:
        this.commonService.setValidationErrorModal(this.content.validations.registerAccount.errorTitle);
    }
  }
}
