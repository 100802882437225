import {Component, OnInit} from '@angular/core';
import {CONTENT} from '../../content-management/content';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../shared/constants/routing-constants';
import {GtmEventsService} from '../../shared/services/gtm-events.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public content = CONTENT;
  private routes = ROUTINGCONSTANTS;
  private nwUpdateUsernameAndPassword = environment.nwUpdateUsernameAndPassword;

  constructor(private router: Router,
              private gtmService: GtmEventsService) {
  }

  ngOnInit() {
  }

  navChangeLoginID() {
    this.setGTMEvents('Change Login ID');
    // this.router.navigate([this.routes.editLogin]);
    window.open(this.nwUpdateUsernameAndPassword, '_parent');
  }

  navChangePassword() {
    this.setGTMEvents('Change Password');
    // this.router.navigate([this.routes.editPassword]);
    window.open(this.nwUpdateUsernameAndPassword, '_parent');
  }

  navChangeSecQs() {
    this.setGTMEvents('Update Security Question');
    // this.router.navigate([this.routes.editSecQs]);
  }

  onNavigateBack() {
    this.router.navigate([this.routes.home]);
  }

  setGTMEvents(value) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Settings Page Edit Button',
      eventLabel: `${value} Edit Button`,
      eventValue: `Edit Button For: ${value}`,
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(event);
  }

}
