import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EncrDecrService } from '../../../core/services/encr-decr.service';
import { ROUTINGCONSTANTS } from '../../constants/routing-constants';
import { GtmEventsService } from '../../services/gtm-events.service';
import { CONTENT } from '../../../content-management/content';
import moment from 'moment';
import { MyPetsService } from '../../../modules/my-pets/my-pets.service';

@Component({
  selector: 'app-benefits-deductible-gauge',
  templateUrl: './benefits-deductible-gauge.component.html',
  styleUrls: ['./benefits-deductible-gauge.component.scss']
})

export class BenefitsDeductibleGaugeComponent implements OnInit, OnChanges 
{
  public routingConstants = ROUTINGCONSTANTS;
  public annualDeductible = 0;
  public deductibleApplied = 0;
  public remainingDeductible = 0;
  @Input() public slideIndex: any;
  @Input() public policy: any;
  @Input() public isMyPetsPage: any;
  @Input() public policyClaims: any;
  @Input() public coverageData: any;

  public benefits: any = [];
  public benefitsForm: FormGroup;
  public content = CONTENT;
  public termPeriod: any;
  public totalReimbursed = 0;
  public selectedTerm: any;
  public isMPIPolicy = false;

  constructor(private router: Router,
    private encDcrService: EncrDecrService,
    private gtmService: GtmEventsService,
    private formBuilder: FormBuilder,
    private myPetsService: MyPetsService,
  ) { }

  ngOnInit() 
  {
  }

  ngOnChanges() 
  {
    this.setDeductibleGauge();
  }

  setDeductibleGauge() 
  {
    // if (this.policy && this.policy.planCode === "ModularPetInsurance")
    // {
    //   this.annualDeductible = this.policy.deductible;
    //   this.getRemainingDeductible(this.policy.renewalNumber);

    //   if (this.isMyPetsPage) 
    //   {
    //     this.setBenefitsUsedInfo();
    //   }
    // }
    if (this.policy)
    {
      this.annualDeductible = this.policy.deductible;
      this.getRemainingDeductible(this.policy.renewalNumber);
      this.isMPIPolicy = this.policy.planCode === "ModularPetInsurance";
      
      if(this.isMyPetsPage)
      {
        this.setBenefitsUsedInfo();
      }                                               
    }
  }

  setBenefitsUsedInfo() 
  {
    if (this.policy) 
    {
      var effectiveDate = (new Date(this.policy.effectiveDate)).toLocaleDateString();
      var expDate = (new Date(this.policy.expirationDate)).toLocaleDateString();
      this.termPeriod = effectiveDate + ' to ' + expDate;
      if(this.coverageData && this.coverageData.length!=0)
      {
        this.totalReimbursed = this.coverageData.reduce((acc, curr) => +acc + +curr.reimbursedAmt, 0);
      }
    }
  }

  getRemainingDeductible(term: any) 
  {
    this.remainingDeductible = this.policy.deductible;
    if (this.policyClaims && this.policyClaims.ClaimSummaryResponse && this.policyClaims.ClaimSummaryResponse.ClaimSummary) 
    {
      let termClaims = this.policyClaims.ClaimSummaryResponse.ClaimSummary.filter(cl => cl.eOBDate && cl.renewal === term);

      if(termClaims.length == 1)
      {
        this.remainingDeductible = termClaims[0].deductibleRemaining || 0;
      }
      else if(termClaims && termClaims.length > 1)
      {
        let claims = termClaims.sort((curr, acc) => 
        {
          return (new Date(acc.eOBDate).getTime() - new Date(curr.eOBDate).getTime());
        });

        let remainingNew = [];
        for(let i=0; i<claims.length-1; i++)
        {
          if(claims[i].eOBDate === claims[i+1].eOBDate)
          {
            remainingNew.push(claims[i]);
            remainingNew.push(claims[i+1]);
          }
          else 
            break;
        }
        
        if(remainingNew.length > 0)
        {
          let remain = remainingNew.sort((a,b) => a.deductibleRemaining - b.deductibleRemaining);
          this.remainingDeductible = remain[0].deductibleRemaining || 0;
        }
        else
        {
          this.remainingDeductible = claims[0].deductibleRemaining || 0;
        }
      }

    }
    this.deductibleApplied = Number((Number(this.annualDeductible) - Number(this.remainingDeductible)).toFixed(2));
  }

  // getReimbursedAmount() {
  //   this.myPetsService.getCoverageBenefitsByPolicy(this.policy.policyNumber).subscribe(coverage => {
  //     if (coverage && coverage.coverageBenefits) {
  //       this.totalReimbursed = coverage.coverageBenefits.reduce((acc, curr) => +acc + +curr.reimbursedAmt, 0);
  //     }
  //   });
  // }

  goToPets() 
  {
    const gtmEvent = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Clicking coverage details link to MyPets Page',
      eventLabel: 'Homepage Links',
      eventValue: 'Navigated To MyPets Page',
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(gtmEvent);
    this.router.navigate([this.routingConstants.pets, this.encDcrService.set(this.policy.policyNumber)]);
  }
}