import {Component, Input, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {Claim} from '../../../models/claim';
import {IMAGECONSTANTS} from '../../../shared/constants/image-constants';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';
import {EncrDecrService} from '../../../core/services/encr-decr.service';

@Component({
  selector: 'app-most-recent-claim',
  templateUrl: './most-recent-claim.component.html',
  styleUrls: ['./most-recent-claim.component.scss']
})
export class MostRecentClaimComponent implements OnInit {
  public content = CONTENT;
  public routingConstants = ROUTINGCONSTANTS;
  public imageConstants = IMAGECONSTANTS;

  @Input() public submittedDate: string;
  @Input() public claimStatus: string;
  @Input() public petName: string;
  @Input() public claimNumber: string;
  @Input() public claimData: Claim[];
  @Input() public statusBar: any;
  @Input() public policyNumber: string;
  @Input() public renewal: number;
  @Input() public slideIndex: any;
  @Input() public policyRenewal: number;
  @Input() public currentSlideNumber: number;

  constructor(private router: Router,
              private encDcrService: EncrDecrService,
              private gtmService: GtmEventsService) {
    this.checkClaimStatus();
  }

  ngOnInit() {
  }

  checkClaimStatus() {
    if (this.claimStatus === 'In Process') {
      this.claimStatus = 'In Progress';
    }

    return this.claimStatus === 'Complete';
  }

  goToClaims() {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Link to See All Claims',
      eventLabel: 'See All Claims',
      eventValue: 'See All Claims Page',
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(event);
    this.router.navigate([this.routingConstants.claims]);
  }

  navigateToSeeClaimDetails(claimNumber) {
    this.router.navigate([this.routingConstants.claimDetails, claimNumber, this.encDcrService.set(this.policyNumber), this.renewal]);
  }

}
