import { Component, OnInit } from '@angular/core';
import { CONTENT } from '../../../content-management/content';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormValidationConstants } from '../../../shared/constants/form-validation-constants';
import { Router } from '@angular/router';
import { ROUTINGCONSTANTS } from '../../../shared/constants/routing-constants';
import { SettingsService } from '../../../shared/services/settings.service';
import { AuthService } from '../../../core/services/auth.service';
import * as _ from 'lodash';
import { ChangeLoginIdRequest } from '../../../models/request/change-loginId-request';
import { first } from 'rxjs/operators';
import { CommonService } from '../../../shared/services/common.service';
import { GtmEventsService } from '../../../shared/services/gtm-events.service';
import { ChangeLoginIdResponse } from '../../../models/response/change-loginId-response';

@Component({
  selector: 'app-edit-login',
  templateUrl: './edit-login.component.html',
  styleUrls: ['./edit-login.component.scss']
})
export class EditLoginComponent implements OnInit {
  public content = CONTENT;
  public errorMessages = CONTENT.settings.login.errorMessages;
  public labels = CONTENT.settings.login.labels;
  public placeholders = CONTENT.settings.login.placeHolders;
  public lengthValidation = FormValidationConstants.lengthValidation;
  public patternValidation = FormValidationConstants.patternValidation;
  public routes = ROUTINGCONSTANTS;
  public editLoginForm: UntypedFormGroup;
  public changeLoginIdRequest: ChangeLoginIdRequest = {};
  public hide = true;
  public loginId: string;
  public editError = '';

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private settingsService: SettingsService,
              private authService: AuthService,
              private gtmService: GtmEventsService,
              private commonService: CommonService) {
  }

  get formControl() {
    return this.editLoginForm.controls;
  }

  ngOnInit() {
    this.createForm();
    this.loginId = sessionStorage.getItem('loginId');
  }

  authenticate(loginId, password) {
    const userObject = {
      loginId,
      password
    };
    this.authService.authenticate(userObject);
  }

  canSubmit() {
    return this.editLoginForm.valid;
  }

  setFormLoginIdValidators() {
    return [Validators.required, Validators.pattern(this.patternValidation.loginID),
    Validators.maxLength(this.lengthValidation.loginIDMaxLength), Validators.minLength(this.lengthValidation.loginIDMinLength)];
  }

  setFormPasswordValidators() {
    return [Validators.required, Validators.pattern(this.patternValidation.passwordRegex),
    Validators.maxLength(this.lengthValidation.passwordMaxLength), Validators.minLength(this.lengthValidation.passwordMinLength)];
  }

  createForm(): void {
    this.editLoginForm = this.fb.group(
      {
        currentLoginID: ['', this.setFormLoginIdValidators()],
        newLoginID: ['', this.setFormLoginIdValidators()],
        confirmNewLoginID: ['', this.setFormLoginIdValidators()],
        password: ['', this.setFormPasswordValidators()]
      },
      { validator: this.customValidators }
    );
  }

  customValidators(fg: UntypedFormGroup): any {
    let errors = {};
    const confirmLoginIDErrors = fg.get('confirmNewLoginID').errors || {};

    const newLoginID = fg.get('newLoginID').value;
    const confirmLoginID = fg.get('confirmNewLoginID').value;

    if (newLoginID && confirmLoginID && newLoginID !== confirmLoginID) {
      errors['loginIDMismatch'] = true;
      confirmLoginIDErrors['loginIDMismatch'] = true;
    } else {
      errors = null;
      delete confirmLoginIDErrors['loginIDMismatch'];
    }
    const confirmEmpty = _.isEmpty(confirmLoginIDErrors);
    fg.get('confirmNewLoginID').setErrors(confirmEmpty ? null : confirmLoginIDErrors);
    fg.get('confirmNewLoginID').markAsDirty();
    return errors;
  }

  onNavigateBack() {
    this.router.navigateByUrl(this.routes.settings);
  }

  populateDocument() {
    this.changeLoginIdRequest.oldLogin = this.editLoginForm.value['currentLoginID'];
    this.changeLoginIdRequest.newLogin = this.editLoginForm.value['newLoginID'];
   /* this.changeLoginIdRequest = {
      oldLogin: this.editLoginForm.value['currentLoginID'],
      newLogin: this.editLoginForm.value['newLoginID'],
      password: this.editLoginForm.value['password']
    };*/
    return this.changeLoginIdRequest;
  }

  onSubmit() {
    if (this.canSubmit()) {
      this.settingsService
        .changeLoginID(this.populateDocument())
        .pipe(first())
        .subscribe(
          data => {
            if (data.changeLoginIdResult) {
              // this.setChangePassword(data);
              this.setChangePassword();
            } else {
              this.showErrorValidation(data.standardMessage.code);
              // this.showErrorValidation(data.standardMessage.code, data.standardMessage.developerMessage);
            }
            this.setGTMEvent(data);
          }
        );
    }
  }

  setGTMEvent(data) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Form Submit Button',
      eventLabel: 'Change Login ID Submit Button',
      eventValue: `New Login ID: ${this.editLoginForm.value['newLoginID']}`,
      userId: sessionStorage.getItem('userId'),
      error: {
        code: data.standardMessage.code ? data.standardMessage.code : '',
        message: data.standardMessage.developerMessage ? data.standardMessage.developerMessage : ''
      }
    };
    this.gtmService.pushGTMTags(event);
  }

  showErrorValidation(code) {
    if (code === '99') {
      this.commonService.setValidationErrorModal(this.content.validations.settings.changeLoginIdError);
    } else {
      this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
    }
  }

  /*showErrorValidation(code, developerMessage) {
    if (code === '99') {
      this.commonService.setValidationErrorModal(this.content.validations.settings.changeLoginIdError);
    } else if (code === 'Unauthorized') {
      this.commonService.setValidationErrorModal(developerMessage);
    } else {
      this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
    }
  }*/

  setChangePassword() {
    sessionStorage.setItem('userId', this.editLoginForm.value['newLoginID']);
    this.authenticate(this.editLoginForm.value['newLoginID'], this.editLoginForm.value.password);
    this.editError = '';
    this.commonService.setConfirmationModal({header: 'Login ID has been succesfully updated.'}).subscribe({
      next: () => {
        this.router.navigate([this.routes.home]);
      }
    });
  }

  /*setChangePassword(response: ChangeLoginIdResponse) {
    sessionStorage.setItem('userId', this.editLoginForm.value['newLoginID']);
    this.authService.setJWTToken({ jwt: response.jwt }, this.editLoginForm.value['newLoginID']);
    this.editError = '';
    this.commonService.setConfirmationModal({ header: 'Login ID has been succesfully updated.' }).subscribe({
      next: () => {
        this.router.navigate([this.routes.home]);
      }
    });
  }*/

  getErrorField() {
    return this.editError && this.editError.includes('Invalid login') ? this.content.settings.login.editError.login : this.content.settings.login.editError.password || '';
  }
}
