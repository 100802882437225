import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CONTENT} from '../../../content-management/content';
import {ActivatedRoute, Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import {HomepageService} from '../homepage.service';
import {LinkMissingPolicy} from '../../../models/link-missing-policy';
import {CommonService} from '../../../shared/services/common.service';
import {GtmEventsService} from '../../../shared/services/gtm-events.service';

@Component({
  selector: 'app-link-missing-policy',
  templateUrl: './link-missing-policy.component.html',
  styleUrls: ['./link-missing-policy.component.scss']
})
export class LinkMissingPolicyComponent implements OnInit, OnDestroy {

  public missingPolicyForm: UntypedFormGroup;
  public content = CONTENT;
  public routingConstants = ROUTINGCONSTANTS;
  public missingPolicyConfirmation = false;
  public policyNumber: string;
  public userId: string;
  public missingPolicy: LinkMissingPolicy = {};
  public displayProgressSpinner = 0;

  @ViewChild('missingPolicyErrorModal') missingPolicyErrorModal: ElementRef<any>;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private gtmService: GtmEventsService,
              private commonService: CommonService,
              private homepageService: HomepageService) {
    this.getLoginIdFromSession();
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.displayProgressSpinner = 0;
  }

  get formControl() {
    return this.missingPolicyForm.controls;
  }

  createForm() {
    this.missingPolicyForm = this.fb.group({
      policyNumber: ['', Validators.required]
    });
  }

  onNavigateBack() {
    this.router.navigate([this.routingConstants.home]);
  }

  getLoginIdFromSession() {
    this.userId = sessionStorage.getItem('userId');
  }

  getMissingPolicyDetails() {
    this.missingPolicy.policyNumber = this.missingPolicyForm && this.missingPolicyForm.value &&
      this.missingPolicyForm.value['policyNumber'] && this.missingPolicyForm.value['policyNumber'].toUpperCase() || null;
    return this.missingPolicy;
  }

  setGtmEvent(data) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button CLick',
      eventAction: 'Missing Policy Submit Button',
      eventLabel: 'Submit Button For Missing Policy',
      eventValue: `Missing Policy Submit Button For: ${this.missingPolicy.policyNumber}`,
      userId: sessionStorage.getItem('userId'),
      error: {
        code: data.standardMessage.code ? data.standardMessage.code : '',
        message: data.standardMessage.developerMessage ? data.standardMessage.developerMessage : ''
      }
    };
    this.gtmService.pushGTMTags(event);
  }

  onSubmit() {
    const content = this.content.validations.registerAccount;
    this.displayProgressSpinner++;
    this.homepageService.linkMissingPolicy(this.getMissingPolicyDetails()).subscribe({
      next: data => {
        this.displayProgressSpinner--;
        if (data.standardMessage.code === '99') {
          this.commonService.setDialogModal({
            title: content.error,
            header: this.missingPolicyErrorModal.nativeElement.innerHTML,
            content: content.errorMessage
          });
        } else {
          this.missingPolicyConfirmation = true;
          this.commonService.setConfirmationModal({
            header: data.standardMessage.developerMessage
          }).subscribe({next: () => this.router.navigate([this.routingConstants.home])});
        }
        this.setGtmEvent(data);
      },
      error: () => this.displayProgressSpinner--
    });
  }
}
