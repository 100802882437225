export const IMAGECONSTANTS = {
  //nationwideLogo: '/assets/images/landing-page/nationwide-logo.svg',
  nationwideLogo: '/assets/images/landing-page/nationwide-logo.png',
  homepageImage: '/assets/images/landing-page/Portal_Login_Bruce.jpg',
  check: '/assets/images/check-icon.png',
  images: {
    newClaimImage: '/assets/images/portal-images/newclaim_ned.png',
    loginPageBanner: '/assets/images/portal-images/login_page_banner.jpg',
    loginPageMobile: '/assets/images/portal-images/login_mobile.jpg',
    recentClaimImage: '/assets/images/portal-images/noclaims_recent.png',
    historyClaimImage: '/assets/images/portal-images/noclaims_history.png',
    vetHelpLine: '/assets/images/landing-page/vethelpline_portal_logo.png',
    walmart: '/assets/images/landing-page/walmart_logo.png',
  },
  svgLogos: {
    backButton: '/assets/images/svg-icons/nw_portal_icon_150px_back_arrow.svg',
    check: '/assets/images/svg-icons/nw_portal_icon_150px_check.svg',
    loginKey: '/assets/images/svg-icons/nw_portal_icon_150px_login.svg',
    home: '/assets/images/svg-icons/nw_portal_icon_150px_home.svg',
    paws: '/assets/images/svg-icons/nw_portal_icon_150px_paws.svg',
    settings: '/assets/images/svg-icons/nw_portal_icon_150px_settings.svg',
    claim: '/assets/images/svg-icons/nw_portal_icon_150px_file-09.svg',
    user: '/assets/images/svg-icons/nw_portal_icon_150px_account.svg',
    questionMark: '/assets/images/svg-icons/nw_portal_icon_150px_faq.svg',
    notification: '/assets/images/svg-icons/nw_portal_icon_150px_alert.svg',
    arrowRight: '/assets/images/svg-icons/nw_portal_icon_150px_arrow_right.svg',
    arrowLeft: '/assets/images/svg-icons/nw_portal_icon_150px_arrow_left.svg',
    phone: '/assets/images/svg-icons/nw_portal_icon_150px_phone.svg',
    referAFriend: '/assets/images/svg-icons/nw_portal_icon_150px_refer-a-friend.svg',
    companion: '/assets/images/svg-icons/nw_portal_icon_150px_Companion.svg',
    PHZ: '/assets/images/svg-icons/nw_portal_icon_150px_PHZ.svg',
    catImage: '/assets/images/svg-icons/nw_portal_icon_150px_profile_cat.svg',
    dogImage: '/assets/images/svg-icons/nw_portal_icon_150px_profile_dog.svg',
    attachment: '/assets/images/svg-icons/nw_portal_icon_150px_file-22.svg',
    close: '/assets/images/svg-icons/nw_portal_icon_150px_close.svg',
    footerHomeIcon: '/assets/images/svg-icons/mobile-home-outline.svg',
    footerClaimIcon: '/assets/images/svg-icons/mobile-claims-outline.svg',
    footerPawIcon: '/assets/images/svg-icons/mobile-pets-outline.svg',
    footerUserIcon: '/assets/images/svg-icons/mobile-accounts-outline.svg',
    footerFilledHomeIcon: '/assets/images/svg-icons/mobile-home-fill.svg',
    footerFilledClaimIcon: '/assets/images/svg-icons/mobile-claims-fill.svg',
    footerFilledPawIcon: '/assets/images/svg-icons/mobile-pets-fill.svg',
    footerFilledUserIcon: '/assets/images/svg-icons/mobile-accounts-fill.svg',
    money: '/assets/images/svg-icons/Get-Fast.svg',
    call: '/assets/images/svg-icons/Phone.svg',
    arrowR: '/assets/images/svg-icons/Right-Arrow.svg',
    enroll: '/assets/images/svg-icons/Enroll-New-Pet.svg',
    blueDogImage: '/assets/images/svg-icons/dog_blue_image.svg',
    blueCatImage: '/assets/images/svg-icons/cat_blue_image.svg',
    fileIcon: '/assets/images/svg-icons/file_icon.svg',
    infoIcon: '/assets/images/svg-icons/information-icon.svg',
    newClaimIcon: '/assets/images/svg-icons/start-new-claim-svg-icon.svg',
    pillsIcon: '/assets/images/svg-icons/nw_portal_icon_150px_rx.svg',
    accidentIcon: '/assets/images/svg-icons/icon_accident.svg',
    illnessIcon: '/assets/images/svg-icons/icon_illness.svg',
    wellnessIcon: '/assets/images/svg-icons/icon_wellness.svg',
    noIcon: '/assets/images/svg-icons/icon_no.svg',

    // backButton: '/assets/images/svg-icons/nw_portal_icon_150px_back_arrow.png',
    // check: '/assets/images/svg-icons/nw_portal_icon_150px_check.png',
    // loginKey: '/assets/images/svg-icons/nw_portal_icon_150px_login.png',
    // home: '/assets/images/svg-icons/nw_portal_icon_150px_home.png',
    // paws: '/assets/images/svg-icons/nw_portal_icon_150px_paws.png',
    // settings: '/assets/images/svg-icons/nw_portal_icon_150px_settings.png',
    // claim: '/assets/images/svg-icons/nw_portal_icon_150px_file-09.png',
    // user: '/assets/images/svg-icons/nw_portal_icon_150px_account.png',
    // questionMark: '/assets/images/svg-icons/nw_portal_icon_150px_faq.png',
    // notification: '/assets/images/svg-icons/nw_portal_icon_150px_alert.png',
    // arrowRight: '/assets/images/svg-icons/nw_portal_icon_150px_arrow_right.png',
    // arrowLeft: '/assets/images/svg-icons/nw_portal_icon_150px_arrow_left.png',
    // phone: '/assets/images/svg-icons/nw_portal_icon_150px_phone.png',
    // referAFriend: '/assets/images/svg-icons/nw_portal_icon_150px_refer-a-friend.png',
    // companion: '/assets/images/svg-icons/nw_portal_icon_150px_Companion.png',
    // PHZ: '/assets/images/svg-icons/nw_portal_icon_150px_PHZ.png',
    // catImage: '/assets/images/svg-icons/nw_portal_icon_150px_profile_cat.png',
    // dogImage: '/assets/images/svg-icons/nw_portal_icon_150px_profile_dog.png',
    // attachment: '/assets/images/svg-icons/nw_portal_icon_150px_file-22.png',
    // close: '/assets/images/svg-icons/nw_portal_icon_150px_close.png',
    // footerHomeIcon: '/assets/images/svg-icons/mobile-home-outline.png',
    // footerClaimIcon: '/assets/images/svg-icons/mobile-claims-outline.png',
    // footerPawIcon: '/assets/images/svg-icons/mobile-pets-outline.png',
    // footerUserIcon: '/assets/images/svg-icons/mobile-accounts-outline.png',
    // footerFilledHomeIcon: '/assets/images/svg-icons/mobile-home-fill.png',
    // footerFilledClaimIcon: '/assets/images/svg-icons/mobile-claims-fill.png',
    // footerFilledPawIcon: '/assets/images/svg-icons/mobile-pets-fill.png',
    // footerFilledUserIcon: '/assets/images/svg-icons/mobile-accounts-fill.png',
    // money: '/assets/images/svg-icons/Get-Fast.png',
    // call: '/assets/images/svg-icons/Phone.png',
    // arrowR: '/assets/images/svg-icons/Right-Arrow.png',
    // enroll: '/assets/images/svg-icons/Enroll-New-Pet.png',
    // blueDogImage: '/assets/images/svg-icons/dog_blue_image.png',
    // blueCatImage: '/assets/images/svg-icons/cat_blue_image.png',
    // fileIcon: '/assets/images/svg-icons/file_icon.png',
    // infoIcon: '/assets/images/svg-icons/information-icon.png',
    // newClaimIcon: '/assets/images/svg-icons/start-new-claim-svg-icon.png',
    // pillsIcon: '/assets/images/svg-icons/nw_portal_icon_150px_rx.png',
  },
  marketingBanners: {
    desktop: 'assets/images/sweep-stakes/Jan-2021/NWI_Nike_banner980x220_v4.jpg',
    mobile: 'assets/images/sweep-stakes/Jan-2021/NWI_Nike_banner450x220_v4.jpg'
  }
};
