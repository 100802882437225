import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../core/services/auth.service';
import {CONTENT} from '../../content-management/content';
import {first, tap} from 'rxjs/operators';
import {CommonService} from '../../shared/services/common.service';
import {LoginRequest} from '../../models/request/login-request';
import {HYPERLINKSCONSTANTS} from '../../shared/constants/hyperlinks-constants';
import {IMAGECONSTANTS} from '../../shared/constants/image-constants';
import {ROUTINGCONSTANTS} from '../../shared/constants/routing-constants';
import {FormValidationConstants} from '../../shared/constants/form-validation-constants';
import {StandardMessage} from '../../models/standard-message';
import {LoginResponse} from '../../models/response/login-response';
import {GtmEventsService} from '../../shared/services/gtm-events.service';
import {environment} from '../../../environments/environment';
import {ContentfulService} from '../../shared/services/contentful.service';
import CONTENTFULIDS from '../../shared/constants/contentful-ids';
import { HttpClient } from '@angular/common/http';
import { getCookieValue, UNUM_COOKIE_KEY } from '../../shared/helpers/helper-functions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public reCaptchaSiteKey = environment.reCaptchaSiteKey;
  public redirectUrl = environment.nationwiideRedirectUrl;
  public loginUrl = environment.nationwideLoginUrl;
  public FUPUrl = environment.nationwideFUPUrl;
  public createAccountUrl = environment.nationwideCreateAccountUrl;
  public loginForm: UntypedFormGroup;
  public isSubmitted = false;
  public content = CONTENT;
  public hyperlinkConstants = HYPERLINKSCONSTANTS;
  public imageConstants = IMAGECONSTANTS;
  public routingConstants = ROUTINGCONSTANTS;
  public userInfo: LoginRequest = {};
  public petInfo: any;
  public hide = true;
  public formValidation = FormValidationConstants;
  public standardMessage: StandardMessage;
  public userId: string;
  public isRememberChecked = false;
  public displayProgressSpinner = 0;
  public reCaptchaEnabled = false;
  public bannerMobileUrl = '';
  public bannerDesktopUrl = '';
  public contentfulIds = CONTENTFULIDS;
  @ViewChild('reCaptcha') reCaptcha;
  bannerMobileTitle = '';
  bannerDesktopTitle = '';
  loginImageUrl: any;
  loginImageTitle: any;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private gtmService: GtmEventsService,
    private authService: AuthService,
    private commonService: CommonService,
    private contentfulService: ContentfulService,
    private httpClient: HttpClient) {
  }

  ngOnInit() {
    const isUnumUser = getCookieValue(UNUM_COOKIE_KEY) ? JSON.parse(getCookieValue(UNUM_COOKIE_KEY)) : false;
    if (isUnumUser) {
      this.displayProgressSpinner++;
    }
    // this.reCaptchaEnabled = JSON.parse(String(environment.reCaptchaEnabled).toLocaleLowerCase());
    this.checkForUser();
    this.createForm();
    this.setPetInfo();
    this.populateForm();
    this.getDesktopBannerUrl();
    this.getMobileBannerUrl();
    this.getLoginImage();
    this.getPortalContenful();
    this.getPortalContenfulImage();
    this.getSpeciesIcons();
    //Production Code
    const url = window.location.origin;
    if (url.lastIndexOf('https://d359z6aml7qje9.cloudfront.net') !== -1) {
      this.redirectUrl = environment.nationwiideRedirectCFUrl;
    }
  }

  checkForUser() {
    if (this.authService.currentUserValue) {
      this.router.navigate([this.routingConstants.home]);
    }
  }

  async getLoginImage() {
    const res = await this.contentfulService.getAsset(this.contentfulIds.assets.loginImage);
    if (res) {
      this.loginImageUrl = res.file.url;
      this.loginImageTitle = res.description;
    }
  }

  async getMobileBannerUrl() {
    const res = await this.contentfulService.getAsset(this.contentfulIds.assets.bannerMobile);
    if (res) {
      this.bannerMobileUrl = res.file.url;
      this.bannerMobileTitle = res.description;
    }
  }

  async getDesktopBannerUrl() {
    const res = await this.contentfulService.getAsset(this.contentfulIds.assets.bannerDesktop);
    if (res) {
      this.bannerDesktopUrl = res.file.url;
      this.bannerDesktopTitle = res.description;
    }
  }

  async getPortalContenful() {
    const res = await this.contentfulService.getAsset(environment.portalContentful);
    if (res) {
      this.httpClient.get(res.file.url).subscribe(data =>{
        sessionStorage.setItem('portalContenful', JSON.stringify(data));
      },
      (error) => {
        console.error('Request failed with error');
        console.log(error);
      });
    }
  }

  async getPortalContenfulImage(){
    const res = await this.contentfulService.getEntry(this.contentfulIds.entries.imageIcons);
    if (res) {
         sessionStorage.setItem('portalImageContenful', JSON.stringify(res));
      }
  }

  async getSpeciesIcons(){
    const res = await this.contentfulService.getEntry(this.contentfulIds.entries.speciesIcons);
    if (res) {
      sessionStorage.setItem('portalSpeciesIcons', JSON.stringify(res));
    }
  }

  setPetInfo() {
    const petInfo = this.content.loginPage.info;
    this.petInfo = [petInfo.part1, petInfo.part2, petInfo.part3, petInfo.part4, petInfo.part5, petInfo.part6];
  }

  showLoginIdErrors(formControlName) {
    const error = this.formDetails[formControlName].errors;
    const content = this.content.validations.loginPage;
    if (this.formDetails[formControlName].touched && error) {
      if (error.required) {
        return content.loginError;
      } else if (error.minlength) {
        return content.loginMinError;
      } else if (error.maxlength) {
        return content.loginMaxError;
      }
    }
  }

  showPasswordErrors(formControlName) {
    const error = this.formDetails[formControlName].errors;
    const content = this.content.validations.loginPage;
    if (this.formDetails[formControlName].touched && error) {
      if (error.required) {
        return content.password;
      } else if (error.minlength) {
        return content.passwordMinLength;
      }
    }
  }

  isRememberMeChecked(event) {
    if (event.checked && this.populateDocument()) {
      this.isRememberChecked = true;
    }
  }

  createForm() {
    this.loginForm = this.fb.group({
      loginId: ['', [Validators.required, Validators.minLength(this.formValidation.lengthValidation.loginIDMinLength),
        Validators.maxLength(this.formValidation.lengthValidation.loginIDMaxLength)]],
      password: ['', [Validators.required, Validators.minLength(this.formValidation.lengthValidation.passwordMinLength)]]
    });
  }

  get formDetails() {
    return this.loginForm.controls;
  }

  populateDocument() {
    this.userInfo.loginId = this.loginForm.controls['loginId'].value;
    this.userInfo.password = this.loginForm.controls['password'].value;
    return this.userInfo;
  }

  populateForm() {
    const userInfo = localStorage.getItem('userId');
    if (userInfo) {
      this.loginForm.controls['loginId'].setValue(userInfo);
    }
  }

  setGTMEvents(data) {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Login Button Click',
      eventLabel: 'Login Button',
      eventValue: this.loginForm.controls['loginId'].value,
      userId: sessionStorage.getItem('userId'),
      error: {
        code: data.standardMessage ? data.standardMessage.code : '',
        message: data.standardMessage ? data.standardMessage.developerMessage : ''
      },
    };
    this.gtmService.pushGTMTags(event);
  }

  /*validateReCaptcha(reCaptchaResponse: string) {
    this.reCaptcha.reset();
    this.displayProgressSpinner++;
    const request: VerifyReCaptchaRequest = {
      event: {
        token: reCaptchaResponse,
        siteKey: this.reCaptchaSiteKey
      }
    };
    this.authService.validateReCaptcha(request).subscribe({
      next: response => {
        if (response.riskAnalysis && response.riskAnalysis.score > .5) {
          this.logIn();
        }
      },
      complete: () => {
        this.displayProgressSpinner--;
      }
    });
  }*/

  navigateToNationwideLoginPage(buttonName) {
    sessionStorage.setItem('isPortalUser', 'portalUser');
    this.logLoginPageAnalytics(buttonName);
    if (window.innerWidth <= 768) {
      window.open(this.redirectUrl, '_parent');
    } else {
      window.open(this.redirectUrl, '_parent');
    }
  }

  navigateToNationwideAccountsScreen(buttonName, url) {
    this.logLoginPageAnalytics(buttonName);
    window.open(url, '_parent');
  }

  logLoginPageAnalytics(buttonName) {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: `Button Click`,
      eventAction: `${buttonName} Button Click`,
      eventLabel: `Button for ${buttonName}`,
      eventValue: `Navigated to ${buttonName} Page`,
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(gtmEvents);
  }

  logIn() {
    this.isSubmitted = true;
    this.displayProgressSpinner++;
    this.authService
      .authenticate(this.populateDocument())
      .pipe(first())
      .subscribe({
        next: (data: LoginResponse) => {
          this.checkForAuthenticationErrors(data);
          this.displayProgressSpinner--;
          this.setGTMEvents(data);
        },
        error: () => {
          this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
          this.displayProgressSpinner--;
        },
        complete: () => {
          this.displayProgressSpinner = 0;
        }
      });
  }

  checkForAuthenticationErrors(data: LoginResponse) {
    if (data && data.jwt) {
      if (this.isRememberChecked) {
        localStorage.setItem('userId', this.loginForm.controls['loginId'].value);
      }
      this.router.navigate([this.routingConstants.home]);
    } else {
      this.setAuthenticateValidations(data.standardMessage.code);
    }
  }

  setAuthenticateValidations(code) {
    const content = this.content.validations.registerAccount;
    switch (code) {
      case '96':
        this.commonService.setValidationErrorModal(content.accountLocked);
        break;
      case '98':
        this.commonService.setValidationErrorModal(content.authFail);
        break;
      case '97':
        this.commonService.setValidationErrorModal(content.invalidPassword);
        break;
      case '95':
        sessionStorage.removeItem('userId');
        this.router.navigate([this.routingConstants.editPassword, {
          route: 'loginPage',
          loginId: this.loginForm.controls['loginId'].value
        }]);
        break;
      default:
        this.commonService.setValidationErrorModal(this.content.validations.maintenanceError);
    }
  }

  goToRegister() {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Create Account Button Click',
      eventLabel: 'Create Account Button Click',
      eventValue: 'Navigated to Create Account Page',
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(gtmEvents);
    // this.router.navigate([this.routingConstants.registration]);
    // this.navigateToNationwideLoginPage();
  }

  navigateToForgotId() {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Forgot ID Link Click',
      eventLabel: 'Link for Forgot ID',
      eventValue: 'Navigated to ForgotId Page',
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(gtmEvents);
    // this.router.navigate([this.routingConstants.forgotId]);
    // this.navigateToNationwideLoginPage();
  }

  navigateToForgotPassword() {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Forgot Password Link Click',
      eventLabel: 'Link for Forgot Password',
      eventValue: 'Navigated to Forgot Password Page',
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(gtmEvents);
    // this.router.navigate([this.routingConstants.forgotPassword]);
    // this.navigateToNationwideLoginPage();
  }

  navigateToLoginHelp() {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Need help logging in? Link Click',
      eventLabel: 'Link for Need help logging in?',
      eventValue: 'Navigated to Need help logging in? Page',
      userId: sessionStorage.getItem('userId')
    };
    this.gtmService.pushGTMTags(gtmEvents);
  }
}
