import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTINGCONSTANTS } from '../../../shared/constants/routing-constants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as xml2js from 'xml2js';
import moment from 'moment';
import {OTEDocumentService} from '../../../shared/services/ote-document.service';
import { DataService } from '../../../core/services/data.service';
import { BillingPaymentService } from '../../../shared/services/billing-payment.service';
import { AccountService } from '../../../shared/services/account.service';
import { PaymentInfo } from '../../../models/payment-info';
import { MyPetsService } from '../my-pets.service';
import { MakeAPayment } from '../../../models/make-a-payment';
import { AuthorizePayment } from '../../../models/authorize-payment';
import { NotificationServiceService } from '../../../shared/services/notification-service.service';
import { CommonService } from '../../../shared/services/common.service';
import {ACHCONSTANTS} from '../../../shared/constants/ach-constants';
import { CONTENT } from '../../../content-management/content';
import { PolicyDetails } from '../../../models/policy-details';
import { ErrorModalData } from '../../../models/error-modal-data';
import { GtmEventsService } from '../../../shared/services/gtm-events.service';
import { EncrDecrService } from '../../../core/services/encr-decr.service';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {
  public policyNumber: string;
  public renewalNumber: string;
  public routingConstants = ROUTINGCONSTANTS;
  public makePaymentForm: UntypedFormGroup;
  public showConfirmation = false;
  public petInfo: PolicyDetails;
  public paymentInfo: PaymentInfo;
  public pastDueAmount: MakeAPayment;
  public authorizePayments: AuthorizePayment = {};
  public selectedPayment: number;
  public renewal: any;
  public policyDetailsList: PolicyDetails[];
  public displayProgressSpinner = 0;
  public content = CONTENT;
  public isPendingRenewal: boolean;
  public isNonZeroAmount: boolean;
  public achConstants = ACHCONSTANTS;
  public isTermsCondition = false;
  public policyDetailsNewList = [];
  public installmentPlan = '';
  public payMethod = '';
  modalContent: any;

  constructor(private route: ActivatedRoute,
              private accountService: AccountService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private dataService: DataService,
              private billingService: BillingPaymentService,
              private oteDocumentService: OTEDocumentService,
              private myPetService: MyPetsService,
              private gtmService: GtmEventsService,
              private encDcrService: EncrDecrService,
              private commonService: CommonService,
              private notificationService: NotificationServiceService,
              private billingPaymentService: BillingPaymentService) {
    //this.getPolicyNumberFromRoute();
    //this.ngOnInit();
   // this.goBack();
  }

  ngOnInit() {
    // this.getDataFromService();
    // //this.getPetInfo();
     this.createForm();
    // this.getPaymentInfo();
    // this.getPolicyDetailsList();
    this.getInitialLoad();
  }

getInitialLoad(){
  if(sessionStorage.getItem('policyList')){
    if(this.displayProgressSpinner>0){
      this.displayProgressSpinner--;
    }
    this.getPolicyNumberFromRoute();
    this.goBack();
    this.getDataFromService();
    //this.getPetInfo();
    this.createForm();
    this.getPaymentInfo();
    this.getPolicyDetailsList();

  }else{
    if(this.displayProgressSpinner==0){
      this.displayProgressSpinner++;
    }
    setTimeout(() => { 
    this.getInitialLoad();
    },250);
  }

}


  checkForRenewalStatus() {
    this.getPolicyNumberFromRoute();
    this.myPetService.getPendingRenewalStatus(this.policyNumber, this.petInfo.renewalNumber).subscribe(response => {
      this.isPendingRenewal = response['isPendingRenewal'];
    });
  }

  createForm() {
    this.makePaymentForm = this.fb.group({
      paymentMode: ['', [Validators.required]]
    });
  }

  getPolicyDetailsList() {
    this.displayProgressSpinner++;
    this.billingPaymentService.getPolicyDetail(this.policyNumber).subscribe({
      next: response => {
        if (response) {
          this.petInfo = response;
          this.checkForRenewalStatus();
          this.getPolicyPaymentInfo();
        }
      },
      error: () => {
        this.displayProgressSpinner--;
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  setPaymentType(type) {
    switch (type) {
      case 'ID':
      case 'CC':
        return 'Credit Card';
      case 'IA':
      case 'ACH':
        return 'Debit Card';
      case 'IC':
        return 'Check';
      case 'A1':
        return 'Check Credit';
      case 'A2':
        return 'Check Debit';
      case 'PAYROLL':
          return 'Payroll';
    }
  }

  setPayMethod(type) {
    let res = '';
    switch (type) {
      case 'AMEX':
        res = 'American Express';
        break;
      case 'DISC':
        res = 'Discover';
        break;
      case 'MASTER':
        res = 'Mastercard';
        break;
      case 'VISA':
        res = 'Visa';
        break;
      case 'CHECKING':
        res = 'Checking';
        break;
      case 'SAVINGS':
        res = 'Savings';
        break;
    }
    return res;
  }

  paymentAmountChange(evt) {
    this.isNonZeroAmount = false;
    if (this.pastDueAmount && this.pastDueAmount.policyDuesResponse) {
      const policyDues = this.pastDueAmount.policyDuesResponse;
      const payAmount = evt.value === 'entire' ? policyDues.balance : policyDues.current;
      if (payAmount && payAmount > 0) {
        this.isNonZeroAmount = true;
      }
    }
  }

  getPaymentAmount() {
    if (this.makePaymentForm.value['paymentMode'] === 'entire') {
      this.selectedPayment = this.pastDueAmount.policyDuesResponse.balance;
      this.installmentPlan='Annual';
    } else if (this.makePaymentForm.value['paymentMode'] === 'pastDue') {
      this.selectedPayment = this.pastDueAmount.policyDuesResponse.current;
      this.installmentPlan='Monthly';
    } else {
      this.selectedPayment = this.pastDueAmount.policyDuesResponse.current;
      this.installmentPlan='Monthly';
    }
  }

  private getPolicyNumberFromRoute() {
    this.route.params.subscribe(params => {
      this.policyNumber = this.encDcrService.get(params['policyNumber']);
      this.renewalNumber = params['renewalNumber'];
    });
  }

  goToMyPets() {
    this.router.navigate([this.routingConstants.pets]);
  }

  goToPaymentConfirmation() {
    this.getPaymentAmount();
    return (this.showConfirmation = true);
  }

  goBack() {
    this.showConfirmation = false;
  }

  navigateToEditPayment() {
    this.commonService.setPreviourUrl=this.router.url;
    this.router.navigate([this.routingConstants.editPayment, this.encDcrService.set(this.policyNumber)]);
  }

  getPolicyPaymentInfo() {
    this.billingService.getPaymentInfo(this.policyNumber, Number(this.petInfo.renewalNumber)).subscribe(data => {
      this.paymentInfo = data['paymentInfo'];
    });
  }

  getPaymentInfo() {
    this.myPetService.getPastDuePayments(this.policyNumber).subscribe(value => {
      this.pastDueAmount = value;
    });
  }

  setExpirationDate(card) {
    return card.substring(4, card.length) + '/' + card.substring(0, 4) || '';
  }

  populateDocument() {
    this.getPaymentAmount();
    this.authorizePayments.amount = this.selectedPayment;
    this.authorizePayments.policyNumber = this.policyNumber;
    this.authorizePayments.renewalNumber = this.petInfo.renewalNumber;
    return this.authorizePayments;
  }

  setConfirmationModal(modalData: ErrorModalData) {
    // this.commonService.setConfirmationModal(modalData).subscribe({
    this.commonService.setAchConfirmationModal(modalData).subscribe({
      next: () => {
        this.router.navigate([this.routingConstants.pets]);
      }
    });
  }

  submitPayment() {
    this.displayProgressSpinner++;
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p=>p.policyNumber === this.policyNumber);    
    const accountId = selectedPolicy.accountId;
    this.myPetService.authorizePayment(this.populateDocument(), accountId).subscribe({
      next: data => {
        const code = data.code.toString();
        switch (code) {
          case '201':
            this.notificationService.triggerNotificationsUpdate();
            this.setConfirmationModal({ header: this.content.validations.AuthorizePayments.Success });
            break;
          case '422':
            this.commonService.setValidationErrorModal(this.content.validations.AuthorizePayments.AlreadyCompleted);
            break;
          case '200': //Unable to process payment for this policy.
          case '400':
          case '500':
            this.commonService.setValidationErrorModal(this.content.validations.AuthorizePayments.declined);
            break;
        }
        this.setGTMEvents(data);
      },
      error: (error) => {
        if (error.status === 422) {
          this.commonService.setValidationErrorModal(this.content.validations.AuthorizePayments.AlreadyCompleted);
        }
        else {
          this.commonService.setValidationErrorModal(this.content.validations.AuthorizePayments.declined);
        }
        this.displayProgressSpinner--;
      },
      complete: () => this.displayProgressSpinner--
    });
  }

  setGTMEvents(data) {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Make a Payment Button Click',
      eventLabel: 'Make Payment Button',
      eventValue: data.code,
      userId: sessionStorage.getItem('userId'),
      error: {
        code: data.code ? data.code : '',
        message: data.developerMessage ? data.developerMessage : ''
      }
    };
    this.gtmService.pushGTMTags(gtmEvents);
  }

  getDataFromService() {
    this.dataService = JSON.parse(sessionStorage.getItem('dataService'));
  }

  routeToEditPayment() {
    this.router.navigate([this.routingConstants.account]);
  }

  // getPetInfo() {
  //   _.forEach(this.policyDetailsList, pet => {
  //     if (this.policyNumber === pet.policyNumber) {
  //       this.petInfo = pet;
  //     }
  //   });
  // }

  isTermsConditionChange(event) {
    if (event.checked) {
      this.isTermsCondition = true;
    }else{
      this.isTermsCondition = false;
    }
  }

  getTermsAndConditionModel() {
    const today = moment(new Date()).format('LL');
    const isCreditcard = 'credit card';
    const contentType = this.installmentPlan;
  
    let achContent=this.achConstants.termsAndCondition.content;
    let achSubContent = '';
    if(contentType=='Monthly'){
      if(this.paymentInfo.payMethod=='CC'){
        achContent=this.achConstants.termsAndCondition.recurringBankCardContent;
      }else{
        achContent=this.achConstants.termsAndCondition.recurringACHContent;
      }

    }else if(contentType=='Annual'){
      if(this.paymentInfo.payMethod=='CC'){
        achContent=this.achConstants.termsAndCondition.oneTimeBankCardContent;
      }else{
        achContent=this.achConstants.termsAndCondition.oneTimeACHContent;
      }

    }else if(contentType=='General'){
      if(isCreditcard=='credit card'){
        achContent=this.achConstants.termsAndCondition.recurringBankCardContent;
        achSubContent=this.achConstants.termsAndCondition.oneTimeBankCardContent;
      }else{
        achContent=this.achConstants.termsAndCondition.recurringACHContent;
        achSubContent=this.achConstants.termsAndCondition.oneTimeACHContent;
      }
    }
    achContent=achContent.replace('TODAY',today).replace('CARDTYPE',isCreditcard);
    if(achSubContent!=''){
      achSubContent=achSubContent.replace('TODAY',today).replace('CARDTYPE',isCreditcard);
    }
    this.commonService.setTermsDialogModal({
      header: this.achConstants.termsAndCondition.header,
      content: achContent,
      subContent: achSubContent
    });
  }

  createOteXmlPayload(appliedFormData){
    this.policyDetailsNewList = JSON.parse(sessionStorage.getItem('policyList'));
    const Recipient: any = {};
    const ach: any = {};
    let documentContentXml: any = '';
    this.oteDocumentService.policyNumberList=[];
    const sessionPolicyList = JSON.parse(sessionStorage.getItem('dataService'));
    const renewalData = sessionPolicyList.policyRenewalData;
    let documentName = '';
      if(appliedFormData.payMethod=='CC'){
        if(this.installmentPlan=='Monthly'){
          documentName='POTRBC';
        }else{
          documentName='POTBC';
        }
      }else{
        if(this.installmentPlan=='Monthly'){
          documentName='POTRACH';
        }else{
          documentName='POTACH';
        }
      }

      const Document =[
        {'$' : {
            Name: documentName
        }}
      ];
      const PrintData: any = {};
      const currentPolicy: any = this.policyDetailsNewList.filter(policyDetail => policyDetail.policyNumber == appliedFormData.policyNumber);
      const effectiveDate = moment(currentPolicy[0].effectiveDate).add(1, 'days').format('LL');
      const policyEffectiveDate = moment(currentPolicy[0].effectiveDate).add(30, 'days').format('LL');
    PrintData.Document=Document;
    PrintData.PolicyNumber=appliedFormData.policyNumber;
    Recipient.Name=appliedFormData.firstName+ ' ' +appliedFormData.lastName;
    PrintData.Recipient=Recipient;
    ach.IssueDate=effectiveDate?effectiveDate:'';
    ach.ACHPolicyEffectiveDate=policyEffectiveDate?policyEffectiveDate:'';
    ach.PortalURL=this.achConstants.xmlFormat.portalURL;
    ach.Authdays=this.achConstants.xmlFormat.authdays;
    ach.CallNumber=this.achConstants.xmlFormat.callNumber;
    ach.ValidTime=this.achConstants.xmlFormat.validTime;
    if(appliedFormData.payMethod=='CC'){
      ach.CardType=this.setPayMethod(appliedFormData.acctType);
      ach.LastFour=appliedFormData.maskedAcctNo;
    }else{
      ach.BankRoutingNumber='*****'+this.getLast4(appliedFormData.abaNo);
      ach.BankAccountNumber='******'+this.getLast4(appliedFormData.maskedAcctNo);
      ach.BankAccountType=this.setPayMethod(appliedFormData.acctType);
    }
    this.oteDocumentService.policyNumberList.push(appliedFormData.policyNumber);
    PrintData.ACH=ach;
    const builder = new xml2js.Builder({headless: true , explicitRoot : false , rootName :'PrintData'});
    documentContentXml = builder.buildObject(PrintData);
    const base64EncodedXml = btoa(documentContentXml);
  const oteRequest = {
    'pubName':this.achConstants.oteRequest.publishName,
    'fileName': this.achConstants.oteRequest.fileName,
    'referenceArea': this.achConstants.oteRequest.referenceArea,
    'driverFile': base64EncodedXml
  };
  this.oteDocumentService.requestJsonObject = oteRequest;
  //this.downloadAgreement();
  }

  getLast4(s: string) {
    let last4 = '';
    if (s) {
      if (s.length > 4) {
        last4 = s.slice(s.length - 4);
      } else {
        last4 = s;
      }
    }
    return last4;
  }
  canSubmit(): boolean {
    return ((this.petInfo.authInd=='N' || this.petInfo.authInd==null)  && this.isTermsCondition) || (this.petInfo.authInd=='Y' && !this.isTermsCondition);
  }

  downloadAgreement(){

    this.oteDocumentService.getOteDocument().subscribe(data => {
      const responseData: any = data; 
      const today = moment(new Date()).format('YYYYMMDD');
      const policyNumber = this.oteDocumentService.policyNumberList[0];
      if (responseData.fileOutput) {
        const newBlob = new Blob([responseData.fileOutput]);
        const linkSource = `data:application/pdf;base64,${responseData.fileOutput}`;
        if (window.navigator.userAgent.includes('Firefox') === true) {
          const data1 = linkSource;//window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = data1;
          link.className = 'hidden';
          link.download = 'PaymentAuthorization_'+today+'.pdf';
          
          link.click();
        } else {
          const pdfFileUrlData = linkSource;//URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.download = 'PaymentAuthorization_'+today+'.pdf';
          link.href = pdfFileUrlData;
          link.click();
          
        }
      }
    });


  }

  submitValidate(){
    const authInd = this.petInfo.authInd;
    this.createOteXmlPayload(this.paymentInfo);
    if(authInd && authInd=='Y'){
      this.submitPayment();
    }else{
      this.accountService.updatePaymentAuthorization(this.applyPaymentAuthRequest()).subscribe({
        next: paymentAuthdata => {
                  this.submitPayment();
              },
              error: error => {
                this.updatePaymentAuthErrorModal();
              }});
            }
          }
          

  applyPaymentAuthRequest() {
    const paymentAuthList: any = [];
    const paymentInfoList: any[] = [this.paymentInfo];
    paymentInfoList.forEach(paymentInfo => {
      let paymentType = '';
      let accountNo = '';
      let ccNo = '';
      if (paymentInfo.payMethod != 'CC') {
        paymentType = paymentInfo.acctType;// this.setACHPayMethod(paymentInfo.acctType);
        accountNo = paymentInfo.maskedAcctNo;
      } else {
        paymentType = paymentInfo.acctType;//this.setACHPayMethod(paymentInfo.acctType);
        ccNo = paymentInfo.maskedAcctNo;
      }
      const paymentAuthObj: any = {
        'policyNumber': paymentInfo.policyNumber,
        'termNumber': paymentInfo.renewal,
        'lastFourDigitsCCNo': ccNo,
        'achAccount': this.getLast4(accountNo),
        paymentType,
        'authInd': 'Y',
        'salesChannel': 'PHP'
      };
      paymentAuthList.push(paymentAuthObj);
    });
    return paymentAuthList;
  }

  setACHPayMethod(type) {
    let res = '';
    switch (type) {
      case 'AX':
        res = 'AMEX';
        break;
      case 'DI':
        res = 'DISC';
        break;
      case 'MC':
        res = 'Master';
        break;
      case 'VI':
        res = 'VISA';
        break;
      case 'CHECKING':
        res = 'CHECKING';
        break;
      case 'SAVING':
        res = 'SAVINGS';
        break;
    }
    return res;
  }
  updatePaymentAuthErrorModal() {
    this.commonService.setDialogModal({
      title: 'Error',
      header: this.modalContent ? this.modalContent : 'Error updating payment Authorization information.',
      content: '',
    });
  }
}
