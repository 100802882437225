import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { SplunkLogRequest } from '../../models/splunk-log-request';
import { SplunkLogEvent } from '../../models/splunk-log-event';
import { SplunkLogLog } from '../../models/splunk-log-log';
import {CommonService} from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class FileNewClaimService {
  public submitNewClaimURL = environment.baseUrl + 'claims-administration/pet-php-claims/v1/';
  public policyUrl = environment.baseUrl + 'policy-management/pet-php-policies/v1/';
  public fileList: any[] =[];

  constructor(private http: HttpClient,private commonService: CommonService) {
  }

  // upload(policyNumber, renewalNumber, formData): Observable<any> {
  //   return this.http.post(this.submitNewClaimURL + policyNumber + '/' + renewalNumber + '/' + 'upload-files', formData)
  //     .pipe(map(response => {
  //         return response;
  //       })
  //     );
  // }
  
  policyMigration(policyNumber) 
  {
    return this.http.get(this.policyUrl + policyNumber + '/lookup').pipe(map(response => response));
  }

  upload(policyNumber, renewalNumber, formData, policyMigrated): Observable<any> {
    formData.append('policyMigrated', policyMigrated);
    this.splunkLogforRequest(policyNumber, renewalNumber, formData);
    const startDate = (new Date(formData.get("treatmentDateFrom"))).toISOString().split('T')[0].replace(/-/g, '');
    const endDate = (new Date(formData.get("treatmentDateTo"))).toISOString().split('T')[0].replace(/-/g, '');
        
    return this.http.post(this.submitNewClaimURL + 'claims/' + policyNumber + '/' + renewalNumber +'?treatmentDateFrom='+ startDate +'&treatmentDateTo='+ endDate, formData)
      .pipe(map(response => {
          return response;
        })
      );
  }

  splunkLogforRequest(policyNumber, renewalNumber, formData){
    const event = new SplunkLogEvent();
    event.app = 'pet_nmp';
    event.level = 'INFO';
    event.env = environment.env;
    event.message = 'File a new Claim - Request Log';
    event.url = this.submitNewClaimURL + 'claims/' + policyNumber + '/' + renewalNumber;

    const outputObj: any={};
    const fileObj: string[]=[];
    this.fileList=[];
    let count=0;
    for(const value of formData.values()) {
      if(count==0){
        outputObj.diagnosis=value;
      }else if(count==1){
        outputObj.hospitalClinicName=value;
      }else if(count==2){
        outputObj.injuryOrIllness=value;
      }else if(count==3){
        outputObj.totalClaimSubmitted=value;
      }else if(count==4){
        outputObj.treatmentDateFrom=value;
      }else if(count==5){
        outputObj.treatmentDateTo=value;
      } else if (count > 5) {
        fileObj.push(value.name + ':' + this.commonService.formatBytes(value.size));
        this.fileList.push(value.name);
      }
      count++;
    }
    outputObj.filename=fileObj;
    setTimeout(() => {
    event.payLoad = JSON.stringify(outputObj);
    const d = new Date();
    event.requestTimeStamp=d.toString();

    const log = new SplunkLogLog();
    log.event = JSON.stringify(event);
    log.fields = '{}';
    log.sourceType = 'nmp_json';

    const payLoad = new SplunkLogRequest();
    payLoad.splunkToken = environment.splunkToken;
    payLoad.logs = [];
    payLoad.logs.push(log);

    this.commonService.logErrorToSplunk(payLoad).subscribe(x => { });
      
    }, 500);

  }
}
